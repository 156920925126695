import React from "react";
import { TypeAnimation } from "react-type-animation";

export default function About() {
    return (
        <section id="about">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium dark:text-white min-h-[80px]">
                        <TypeAnimation
                            sequence={[
                                "Hi, I'm Brandon Clark.",
                            ]}
                            wrapper="div"
                            cursor={false}
                        />
                        <TypeAnimation
                            sequence={[
                                2500,
                                "And I love building apps.",
                                1250,
                                "And I love building amazing apps!"
                            ]}
                            wrapper="div"
                            cursor={false}
                            deletionSpeed={20}
                        />
                    </h1>
                    <p className="mb-8 leading-relaxed">
                        I enjoy working with fun, collaborative teams to help build innovative and scalable solutions to customer problems.
                        When I'm not at work, you'll find me learning about new and upcoming technologies or snuggling with my cats.
                        I also enjoy creating and playing games!
                    </p>
                    <div className="flex justify-center">
                        <a
                            href="#contact"
                            className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                            Work With Me
                        </a>
                        <a
                            href="#projects"
                            className="ml-4 inline-flex text-white dark:text-gray-400 bg-sky-400 dark:bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-sky-600 hover:dark:bg-gray-700 hover:text-white rounded text-lg">
                            View My Work
                        </a>
                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 z-0">
                    <img
                        className="object-cover object-center rounded-full"
                        alt="hero"
                        src="/catPic.jpg"
                    />
                </div>
            </div>
        </section>
    );
}