import React, { Component } from 'react';
import DarkModeToggle from "react-dark-mode-toggle";

class Navbar extends Component {
    render() {
        return (
            <header className="bg-sky-100 dark:bg-gray-800 md:sticky top-0 z-10">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="#about" className="title-font font-medium dark:text-white mb-4 md:mb-0 ml-3 text-xl">
                        Brandon Clark
                    </a>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:dark:border-gray-700	flex flex-wrap items-center text-base justify-center">
                        <a href="#projects" className="mr-5 hover:dark:text-white">
                            Projects
                        </a>
                        <a href="#skills" className="mr-5 hover:dark:text-white">
                            Skills
                        </a>
                        <a href="#testimonials" className="mr-5 hover:dark:text-white">
                            Testimonials
                        </a>
                    </nav>
                    <a
                        href="#contact"
                        className="inline-flex items-center dark:bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:dark:bg-gray-700 rounded text-base mt-4 md:mt-0">
                        Contact Me
                    </a>
                    <DarkModeToggle
                        onChange={(isChecked) => this.props.onUpdate(isChecked)}
                        checked={this.props.isDarkMode}
                        size={50}
                    />
                </div>
            </header>
        );
    }
}

export default Navbar;