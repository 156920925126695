import React from "react";
import { CommandLineIcon, UsersIcon } from "@heroicons/react/24/solid";
import { testimonials } from "../data/data";

export default function Testimonials() {
    return (
        <section id="testimonials">
            <div className="container px-5 py-10 mx-auto text-center">
                <UsersIcon className="w-10 inline-block mb-4" />
                <h1 className="sm:text-4xl text-3xl font-medium title-font dark:text-white mb-12">
                    Testimonials
                </h1>
                <div className="flex flex-wrap m-4">
                    {testimonials.map((testimonial, index) => (
                        <div className="p-4 md:w-1/2 w-full" key={index}>
                            <div className="h-full bg-sky-200 dark:bg-gray-800 bg-opacity-40 p-8 rounded">
                                <CommandLineIcon className="block w-8 text-green-500 dark:text-gray-500 mb-4" />
                                <p className="leading-relaxed mb-6">{testimonial.quote}</p>
                                <div className="inline-flex items-center">
                                    <img
                                        alt="testimonial"
                                        src={testimonial.image}
                                        className="w-12 rounded-full flex-shrink-0 object-cover object-center"
                                    />
                                    <span className="flex-grow flex flex-col pl-4"></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}