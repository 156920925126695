const darkModeParticleParms = {
    particles: {
        number: {
            value: 160,
            density: {
                enable: true,
                value_area: 1500,
            },
        },
        line_linked: {
            enable: false,
            opacity: 0.03,
        },
        move: {
            enable: true,
            direction: "right",
            speed: 0.2,
        },
        size: {
            value: 1,
        },
        opacity: {
            anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.05,
            },
        },
        color: {
            value: "#ffffff",
        },
    },
    interactivity: {
        events: {
            onclick: {
                enable: true,
                mode: "push",
            },
        },
        modes: {
            push: {
                particles_nb: 1,
            },
        },
    },
    retina_detect: true,
};

const lightModeParticleParms = {
    particles: {
        number: {
            value: 160,
            density: {
                enable: true,
                value_area: 1500,
            },
        },
        line_linked: {
            enable: false,
            opacity: 0.03,
        },
        move: {
            enable: true,
            direction: "right",
            speed: 0.2,
        },
        size: {
            value: 2,
        },
        opacity: {
            anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.05,
            },
        },
        color: {
            value: "#69C7FE",
        },
    },
    interactivity: {
        events: {
            onclick: {
                enable: true,
                mode: "push",
            },
        },
        modes: {
            push: {
                particles_nb: 1,
            },
        },
    },
    retina_detect: true,
};

export {darkModeParticleParms, lightModeParticleParms};