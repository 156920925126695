export const data = [
    {
        title: "Advanced Rendering Techniques",
        subtitle: "Raytracing",
        description:
            "Simple raytracer I made back in college.",
        image: "./project1.jpg",
        link: "https://github.com/bclarksoftware/RayTracer",
    },
    {
        title: "OpenVSP",
        subtitle: "OpenGL",
        description:
            "Some contributions to the OpenVSP open source project.",
        image: "./project2.png",
        link: "http://openvsp.org/",
    },
    {
        title: "Workday",
        subtitle: "Proprietary OOP Language",
        description:
            "Creating and scaling the financials close & reporting product with Workday.",
        image: "./project3.png",
        link: "https://www.workday.com/",
    },
    {
        title: "Two Cents App",
        subtitle: "Flutter + Dart + Firebase",
        description:
            "Most recent project I've been working on. An app for giving and receiving advice!",
        image: "./project4.png",
        link: "https://flutter.dev/",
    },
];

export const testimonials = [
    {
        quote: "'Our workmates are always great to work with, but Brandon really stood out with his enthusiasm and clear passion for improving our product and our implementations.'",
        image: "./workdayLogo.png",
    },
    {
        quote: "'Brandon's command and ownership of the subject illustrated his ability to simplify the complex.'",
        image: "./workdayLogo.png",
    },
];

export const skills = ["C++", "OpenGL", "Java", "React", "Node", "Express", "MongoDB", "Flutter + Dart", "OOP", "Scrum"];