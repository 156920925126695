import React, { Component } from 'react';
import './App.css';
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Testimonials from "./components/Testimonials";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import {darkModeParticleParms, lightModeParticleParms} from './data/options';
import { Toaster } from 'react-hot-toast';

class App extends Component {
    constructor(props) {
        super(props);

        let darkModeEnabled = ((localStorage.theme === 'dark'
            || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)));

        if (darkModeEnabled) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }

        this.state = {
            apiResponse: '',
            dbResponse: '',
            isDarkMode: darkModeEnabled,
            setInit: false,
        };
    }

    callAPI() {
        fetch('http://localhost:9000/testAPI')
            .then(res => res.text())
            .then(res => this.setState({apiResponse: res}))
            .catch(err => err);
    }

    callDB() {
        fetch('http://localhost:9000/testDB')
            .then(res => res.text())
            .then(res => this.setState({dbResponse: res}))
            .catch(err => err);
    }

    componentDidMount() {
        initParticlesEngine(async (engine) => {
           await loadSlim(engine);
        }).then(() => {
            this.setState({
                setInit: true
            });
        });

        //this.callAPI();
        //this.callDB();
    }

    onUpdateDarkMode(isChecked) {
        if (!this.state.isDarkMode) {
            localStorage.theme = 'dark';
            document.documentElement.classList.add('dark');
        } else {
            localStorage.theme = 'light';
            document.documentElement.classList.remove('dark');
        }

        this.setState({
            isDarkMode: isChecked
        })
    }

    render() {
        return (
            <div className="App">
                <Particles id="tsparticles" options={this.state.isDarkMode
                    ? darkModeParticleParms : lightModeParticleParms}/>

                <main className="dark:text-gray-400 dark:bg-gray-900 body-font">
                    <Navbar isDarkMode={this.state.isDarkMode} onUpdate={this.onUpdateDarkMode.bind(this)}/>
                    <About/>
                    <Projects/>
                    <Skills/>
                    <Testimonials/>
                    <Contact/>
                    <Toaster
                        position="bottom-center"
                        reverseOrder={false}
                    />
                </main>
            </div>
        );
    }
}

export default App;