import React, {Component} from 'react';
import toast from 'react-hot-toast';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {name: "", email: "", message: ""};
    }

    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact", ...this.state})
        })
            .then(() => {
                this.setState({name: "", email: "", message: ""});
                toast.success("Thank you for reaching out!");
            })
            .catch(error => {
                console.log(error);
                toast.error("Sorry, unable to submit at this time.");
            });

        e.preventDefault();
    };

    handleChange = e => this.setState({[e.target.name]: e.target.value});

    render() {
        const {name, email, message} = this.state;

        return (<section id="contact" className="relative">
                <div className="container px-5 py-10 mx-auto">
                    <form
                        onSubmit={this.handleSubmit}
                        name="contact"
                        className="lg:w-1/3 flex flex-col mx-auto">
                        <h2 className="dark:text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
                            Contact Me
                        </h2>
                        <p className="leading-relaxed mb-5">
                            Feel free to reach out if you are interested in working with me.
                        </p>
                        <div className="relative mb-4">
                            <label htmlFor="name" className="leading-7 text-sm dark:text-gray-400">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={this.handleChange}
                                className="w-full bg-sky-50 dark:bg-gray-800 rounded border border-gray-700
                                focus:dark:border-indigo-500 focus:ring-2 focus:dark:ring-indigo-900 text-base
                                outline-none dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm dark:text-gray-400">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                                className="w-full bg-sky-50 dark:bg-gray-800 rounded border border-gray-700
                                focus:dark:border-indigo-500 focus:ring-2 focus:dark:ring-indigo-900 text-base
                                outline-none dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div className="relative mb-4">
                            <label
                                htmlFor="message"
                                className="leading-7 text-sm dark:text-gray-400">
                                Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={message}
                                onChange={this.handleChange}
                                className="w-full bg-sky-50 dark:bg-gray-800 rounded border border-gray-700
                                focus:dark:border-indigo-500 focus:ring-2 focus:dark:ring-indigo-900 h-32 text-base
                                outline-none dark:text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <button
                            type="submit"
                            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Submit
                        </button>
                    </form>
                </div>
            </section>);
    }
}

export default Contact;